import React,{Suspense } from 'react'
import estuaireFR from '../media/Estuaire.jpg'
import oceanFR from '../media/Ocean.jpg'
import plageFR from '../media/Plage.jpg'
import portFR from '../media/Port.jpg'
import zoneHumideFR from '../media/Zone_Humide.jpg'
import estuaireAR from '../media/estuaireAR.jpeg'
import oceanAR from '../media/oceanAR.jpeg'
import plageAR from '../media/plageAR.jpeg'
import portAR from '../media/portAR.jpeg'
import zoneHumideAR from '../media/zoneHumideAR.jpeg'
import HomeCard from './HomeCard'
import { useTranslation } from 'react-i18next';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Sparkle from 'react-sparkle'
import son from '../media/boundif_son.png'
import father from '../media/boundif_father.png'
import laptop from '../media/laptop.webp'
import animated from '../media/Animated.gif'
import maFlag from '../media/ma_flag.png'
import frFlag from '../media/fr_flag.png'


function Hero() {

  AOS.init()

  const { t, i18n } = useTranslation()

  let HTML = document.querySelector('#HTML')
  const offCanvas = document.querySelector('#offcanvasScrolling')
  const OffcanvasLinks = document.querySelector('.offcanvas-body .list-group')
  const LanguageToFr = ()=>{
    i18n.changeLanguage('fr')
    HTML.attributes.dir.textContent="ltr" 
    offCanvas.classList.remove('offcanvas-end')
    offCanvas.classList.add('offcanvas-start')

  }
  const LanguageToAr = ()=>{
    i18n.changeLanguage('ar')
    HTML.attributes.dir.textContent="rtl"
    offCanvas.classList.remove('offcanvas-start')
    offCanvas.classList.add('offcanvas-end')

  }

  return (
      <>


<div className="modal w-100 fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      
  <div className="modal-dialog border border-3 border-success rounded-2">
    <div className="modal-content text-light" style={{background:'#000000b0'}}>
    <Sparkle    
        count={50}
        minSize={8}
        maxSize={15}
        overflowPx={20}
        fadeOutSpeed={25}
        newSparkleOnFadeOut={true}
        flicker={true}
        flickerSpeed={'normal'}
        color={'#fff'} />
    <img src={son} style={{width:'200px'}} className='position-absolute top-50 start-100 translate-middle mt-5' />

      <div className="modal-header fs-5">
        <h5 className="modal-title fw-bold" id="staticBackdropLabel">{t('bienvenue')} <span className="text-success"><i className='fa-solid text-success fa-leaf me-1 fs-6'></i>{t('logo')}</span> !</h5>
        {/* <button type="button" className="btn-close bg-light" data-bs-dismiss="modal" aria-label="Close"></button> */}
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" aria-label="Close">X</button>
      </div>
      <div className="modal-body">
      <p className='fs-6 fw-semibold m-auto' style={{textAlign:'justify',width:'90%'}}>{t('alertMsg')} </p>
      </div>
      <div className="modal-footer d-flex justify-content-end">
        <div className="dropdown dropdown-menu-end">
            <button className="d-flex align-items-center btn btn-warning dropdown-toggle ms-auto" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
              <i className='fa-solid fa-language mx-2 fs-4'></i> {t('choixLng')}
            </button>
            <ul className="dropdown-menu dropdown-menu-end">
              <li><button onClick={LanguageToAr} className="dropdown-item" type="button"><img width={'20px'} src={maFlag} /> {t('lngArb')}</button></li>
              <li><button onClick={LanguageToFr} className="dropdown-item" type="button"><img width={'20px'} src={frFlag} /> {t('lngFr')}</button></li>
            </ul>
          </div>   
      </div>
    </div>
  </div>
</div>

<div
data-aos="fade-down"
    data-aos-offset="300"
    data-aos-delay="50"
    data-aos-duration="1000"
    data-aos-easing="ease-in-out"
    data-aos-mirror="true"
    data-aos-once="false"
 className="container my-5 mx-md-auto position-relative" style={{opacity:0.85,boxShadow: '0px 1px 3px 5px #ffffff5e'}}>
       
    <div className="border border-5 border-success bg-light row p-4 align-items-center rounded-3" style={{background:' #ffffff96 !important'}}>
      <div className="col p-3 p-lg-5 pt-lg-3">
        {/* <img src={father} alt="" className='position-absolute top-50 start-0 translate-middle' /> */}

        <h1 className="fs-4 fw-bold">{t('bienvenue')} <span className='fw-bold text-success'><i className="fa-solid text-success fa-leaf me-1 fa-lg"></i>{t('logo')}</span></h1>

        <ul className="list-unstyled mt-5">
          <li className='fs-5 p-1 lead'>{t('presentationTitle1')}</li>
          <li className='fs-5 p-1 lead'>{t('presentationTitle2')}</li>
          <li className='fs-5 p-1 lead'>{t('presentationTitle3')} 
            <ul>
              <li className='fs-5 p-1 lead'>{t('presentaionList1')}</li>
              <li className='fs-5 p-1 lead'>{t('presentaionList2')}</li>
              <li className='fs-5 p-1 lead'>{t('presentaionList3')}</li>
            </ul>
          </li>
          <li></li>
        </ul>
      </div>
    </div>
  </div>
    <div className='mt-5 m-auto'style={{width:'90%'}}>
        <div className="row gap-3 mb-5">
          <HomeCard media="../Backgrounds/Estuaire.mp4" title={t('estuaire')} desc={t('estuaireDesc')} img={i18n.language === 'fr' ? estuaireFR : estuaireAR} downloadLink="/download/Estuaire" play="games/estuaire" />
          <HomeCard media="../Backgrounds/Ocean.mp4" title={t('ocean')} desc={t('oceanDesc')} img={i18n.language === 'fr' ? oceanFR : oceanAR} downloadLink="/download/Ocean" play="games/Ocean" />
          <HomeCard media="../Backgrounds/Plage.mp4" title={t('plage')} desc={t('plageDesc')} img={i18n.language === 'fr' ? plageFR : plageAR} downloadLink="/download/Plage" play="games/Plage" />
          <HomeCard media="../Backgrounds/Port.mp4" title={t('port')} desc={t('portDesc')} img={i18n.language === 'fr' ? portFR : portAR} downloadLink="/download/Port" play="games/port" />
          <HomeCard media="../Backgrounds/Zonehumide.mp4" title={t('zoneHumide')} desc={t('zoneHumideDesc')} img={i18n.language === 'fr' ? zoneHumideFR : zoneHumideAR} downloadLink="/download/ZoneHumid" play="games/zonehumide" />
          <HomeCard media="../Backgrounds/Ocean.mp4" title={t('version Integrale')} desc={t('versionIntegrale')} img={animated} downloadFile="../../downloads/integrale.rar" />
        </div>
    </div>
  </>


  )
}

export default Hero