import React from 'react'
import Intellcap from '../media/intellcap.png'
import { useTranslation } from 'react-i18next';



function Footer() {

  const { t, i18n } = useTranslation()

  return (
    <>
        <div className="container my-3">
          <footer className="d-flex flex-wrap justify-content-between align-items-center mt-3 border-top">
            <a href="/" className="btn-light rounded mb-3 m-2 mb-md-0 text-muted text-decoration-none">
                <img className='m-2' style={{width:'170px'}} src={Intellcap} alt="Intellcap Logo"  />
            </a>
            <div className="d-flex align-items-center mt-2">
              <p className='mb-2 text-light fs-6'>{t('copyrightP')} Intellcap © 2022 </p>
            </div>
          </footer>
        </div>
    </>
  )
}

export default Footer