import { BrowserRouter as Router,Routes, Route } from "react-router-dom";
import Main from "./pages/Main";
import Home from "./pages/Home";
import DownloadPage from "./Components/DownloadPage";
import Estuaire from './media/Estuaire.jpg'
import Ocean from './media/Ocean.jpg'
import Plage from './media/Plage.jpg'
import Port from './media/Port.jpg'
import Zone_Humide from './media/Zone_Humide.jpg'
import Footer from "./Components/Footer";
import { useTranslation } from 'react-i18next';
import ErrorPage from "./Components/ErrorPage";


function App() {

  const { t, i18n } = useTranslation()

  return (
    <div id="app">
    
    <Router>
    <Main/>
      <Routes>
      <Route exact path="/" element={<Home/>}/>
      <Route path="/download/Estuaire" element={<DownloadPage thematique={t('estuaire')} MobileLink="../../downloads/estuaireFr-Ar.apk" imgLink={Estuaire} DesktopLink="../../downloads/estuaire.zip" />} />
      <Route path="/download/Ocean" element={<DownloadPage thematique={t('ocean')} MobileLink="../../downloads/oceanFr-Ar.apk" imgLink={Ocean} DesktopLink="../../downloads/ocean.zip" />} />
      <Route path="/download/Plage" element={<DownloadPage thematique={t('plage')} MobileLink="../../downloads/plageFr-Ar.apk" imgLink={Plage} DesktopLink="../../downloads/plage.zip" />} />
      <Route path="/download/Port" element={<DownloadPage thematique={t('port')} MobileLink="../../downloads/portFr-Ar.apk" imgLink={Port} DesktopLink="../../downloads/port.zip" />} />
      <Route path="/download/ZoneHumid" element={<DownloadPage thematique={t('Zone humide')} MobileLink="../../downloads/zoneHumideFr-Ar.apk" imgLink={Zone_Humide} DesktopLink="../../downloads/zoneHumide.zip" />} />
      <Route path="*" element={<Home/>} />
      </Routes>
    <Footer/>
    </Router>
    
    </div>

  );
}

export default App;
