import React,{useEffect} from 'react'
import Logo from '../media/logo.png'
import Intellcap from '../media/intellcap.png'
import maFlag from '../media/ma_flag.png'
import frFlag from '../media/fr_flag.png'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import Offcanvas from './Offcanvas'


function RespNav() {

  const { t, i18n } = useTranslation()

    const checkLangue = ()=>{
      if (localStorage.hasOwnProperty('langue') !== false)
    {
    let langue = localStorage.getItem('langue')
     i18n.changeLanguage(langue)
     if(langue==='ar'){
      LanguageToAr()
     }
    }
    }

  let HTML = document.querySelector('#HTML')
  const offCanvas = document.querySelector('#offcanvasScrolling')
  const OffcanvasLinks = document.querySelector('.offcanvas-body .list-group')

  const LanguageToFr = ()=>{
    i18n.changeLanguage('fr')
    localStorage.setItem('langue','fr')
    HTML.attributes.dir.textContent="ltr" 
    offCanvas.classList.remove('offcanvas-end')
    offCanvas.classList.add('offcanvas-start')

  }
  const LanguageToAr = ()=>{
    i18n.changeLanguage('ar')
    localStorage.setItem('langue','ar')
    HTML.attributes.dir.textContent="rtl"
    offCanvas.classList.remove('offcanvas-start')
    offCanvas.classList.add('offcanvas-end')

  }
 

  return (
    
    <>
    <nav id=''
    data-aos="fade-down"
    data-aos-offset="0"
    data-aos-delay="0"
    data-aos-duration="1000"
    data-aos-easing="ease-in-out"
    data-aos-mirror="true"
    data-aos-once="false"
     className="navbar navbar-expand-lg bg-light shadow border-bottom border-success border-5 mb-3" dir="ltr">
      <div className="container-fluid">
      <button className="btn btn-light me-2 fs-6" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling"><i className="fa-solid fa-bars fs-3"></i> </button>
          <Link style={{fontFamily:'Mali'}} to="/" className="navbar-brand img-fluid d-none d-lg-block">
            {/* <img src={Intellcap} alt="Logo" width="200" className="d-none d-md-inline-block p-0" /> */}
            <span className='fw-bold text-success fs-5'><i className="fa-solid fa-lg text-success fa-leaf me-1"></i>{t('logo')}</span>
          </Link>
          <div className="dropdown dropdown-menu-end d-flex me-auto">
            <button className="btn btn-transparent dropdown-toggle ms-auto" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
              <i className='fa-solid fa-language fs-4'></i>
            </button>
            <ul className="dropdown-menu dropdown-menu-end">
              <li><button onClick={LanguageToAr} className="dropdown-item text-center" type="button"><img width={'20px'} src={maFlag}/> AR</button></li>
              <li><button onClick={LanguageToFr} className="dropdown-item text-center" type="button"><img width={'20px'} src={frFlag}/> FR</button></li>
            </ul>
          </div>
          <a className="ms-auto navbar-brand img-fluid p-0 d-lg-none" href="#">
            <img src={Logo} alt="" width="215" className="d-inline-block p-0" />
          </a>
          
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <div className='ms-auto'>
            <a className="navbar-brand img-fluid p-0 d-none d-lg-block" href="#">
              <img src={Logo} alt="" width="200" className="p-0" />
            </a>
          </div>
        </div>
      </div>
  </nav>

    </>
  )
}

export default RespNav