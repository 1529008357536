import React,{useState} from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import './Styles.css'



function HomeCard(props) {

    const changeBackground = ()=>{
        var videocontainer = document.getElementById('background-video')
        var videosource = document.getElementById('source')
        var newmp4 = props.media
        // videocontainer.pause()
        videosource.setAttribute('src', newmp4)
        videocontainer.load()
        videocontainer.play()
    }

    const { t, i18n } = useTranslation()

  return (
        <div data-aos="zoom-in"
         className="col-12 col-sm-6 col-md-4 col-lg-3 m-auto">
            <div id='borderGreen' className="border-0 hover-effect transition card m-auto shadow mb-5" style={{width:"18rem",height:"28rem"}}>
                <img src={props.img} className="card-img-top" style={{height:'14.5rem'}} />
            <div className="card-body bg-yellow bg-gradient">
                <h3 className="card-title fs-4 fw-bold mt-3"><i className="fa-solid fa-gamepad me-1"></i> {props.title}</h3>
                <p className="card-text fs-5">
                    {props.desc}
                </p>
                
                {props.play &&<a href={props.play} className="me-2 hover-effect-border rounded-pill btn btn-light m-1 btn-sm position-absolute top-50 end-0 translate-middle-y">
                    <i className="fa-solid fa-play mx-1"></i>{t('jouer')}
                </a>}

            </div>
            {props.downloadLink && <Link onClick={changeBackground} to={props.downloadLink} className="hover-effect-border card-footer d-flex justify-content-evenly p-1 nav-link">
                <span className="fs-6 p-2">
                <i className="fa-solid fa-cloud-arrow-down me-2"></i>
                    {t('détails & téléchargement')}
                </span>
            </Link>}
            {props.downloadFile && <a className='nav-link text-center' href={props.downloadFile}><i className="fa-solid fa-download me-1"></i> Telecharger le fichier</a>
            
            }
            </div>
        </div>
  )
}

export default HomeCard