import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import mobile from '../media/mobile.png'
import laptop from '../media/laptop.png'
import daughter from '../media/boundif_daughter.png'



function DownloadPage(props) {

    const { t, i18n } = useTranslation()

  return (

    <div className="container my-2 text-light">
        <nav aria-label="breadcrumb">
        <ol className="breadcrumb bg-light p-3 rounded d-flex justify-content-between">
            <li className="breadcrumb-item"><Link to='/'><i className="fa-solid fa-house mx-2"></i>{t('accueil')}</Link></li>
            <h6 className='text-dark fs-4'> <span className="badge bg-success">{props.thematique}</span></h6>

        </ol>
        </nav>

        <div className="jumbotron p-2 rounded bg-transparent">
            <h1 className="display-5"><i className="fa-solid fa-cloud-arrow-down mx-2"></i>{t('page de telechargement')}</h1>
            <p className="lead">{t('Selectioner')}</p>
            <hr className="my-4" />
        </div>

        {/* <div class="bg-light text-dark p-3 rounded border-start border-secondary border-4">
            <h5 id="conveying-meaning-to-assistive-technologies">Packages necessaire. <a class="anchor-link" href="#conveying-meaning-to-assistive-technologies" aria-label="Link to this section: Conveying meaning to assistive technologies"></a></h5>
            <p>L'installation des packages redistribuables VC est nécessaires pour exécuter les jeux.</p>
        </div> */}

        <div className="row d-flex justify-content-center mt-5">
            <div className="col-lg-6 col-xl-3 my-2">    
                <div className="card text-light border-danger m-auto bg-warning" style={{width:'320px'}}>
                    <img className="card-img-top p-3 m-auto" src={mobile} style={{width:'200px',height:'200px'}} />
                    <div className="card-body bg-success opacity-75">
                    <h5 className="card-title"><i className="fa-solid fa-mobile-screen me-1 p-3"></i>{t('version mobile/tablet')}</h5>
                    <a href={props.MobileLink} className="btn btn-success">
                        <i className="fa-solid fa-download mx-1"></i>{t('telecharger')}
                    </a>
                    </div>
                </div>             
            </div>
            <div className="col-lg-6 col-xl-3 my-2">    
                <div className="card text-light border-danger m-auto bg-yellow" style={{width:'320px'}}>
                    <img className="card-img-top p-3 m-auto" src={laptop} style={{width:'200px',height:'200px'}} />
                    <div className="card-body bg-success opacity-75">
                    <h5 className="card-title"><i className="fa-solid fa-computer me-1 p-3"></i>{t('version desktop')}</h5>
                    <a href={props.DesktopLink} className="btn btn-success">
                        <i className="fa-solid fa-download mx-1"></i>{t('telecharger')}
                    </a>
                    </div>
                </div>             
            </div>
            <img src={daughter} alt="" style={{width:'200px'}} className='position-absolute top-50 end-0' />

        </div>
    </div>
            
  )
}

export default DownloadPage